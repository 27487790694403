<template>
  <svg
    role="img"
    aria-label="devices icon"
    class="devices-icon o-icon"
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Devices</title>
    <path
      d="M9.50684 24.9375H4.81934C4.40494 24.9375 4.00751 24.7729 3.71448 24.4799C3.42146 24.1868 3.25684 23.7894 3.25684 23.375V14C3.25684 13.5856 3.42146 13.1882 3.71448 12.8951C4.00751 12.6021 4.40494 12.4375 4.81934 12.4375H9.50684C9.92124 12.4375 10.3187 12.6021 10.6117 12.8951C10.9047 13.1882 11.0693 13.5856 11.0693 14V23.375C11.0693 23.7894 10.9047 24.1868 10.6117 24.4799C10.3187 24.7729 9.92124 24.9375 9.50684 24.9375ZM4.81934 14V23.375H9.50684V14H4.81934Z"
      fill="currentColor"
    />
    <path
      d="M23.5693 4.625H6.38184C5.96744 4.625 5.57001 4.78962 5.27698 5.08265C4.98396 5.37567 4.81934 5.7731 4.81934 6.1875V10.875H6.38184V6.1875H23.5693V17.125H12.6318V18.6875H14.1943V21.8125H12.6318V23.375H19.6631V21.8125H15.7568V18.6875H23.5693C23.9837 18.6875 24.3812 18.5229 24.6742 18.2299C24.9672 17.9368 25.1318 17.5394 25.1318 17.125V6.1875C25.1318 5.7731 24.9672 5.37567 24.6742 5.08265C24.3812 4.78962 23.9837 4.625 23.5693 4.625Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" scoped>
.user-icon {
  height: 28px;
  width: 28px;
}
</style>
