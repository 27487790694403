import { default as _100RsmBlF2vhWMeta } from "/code/pages/100.vue?macro=true";
import { default as aboutKUfNn0t83IMeta } from "/code/pages/about.vue?macro=true";
import { default as collectionsKbOU3DomiRMeta } from "/code/pages/archives/collections.vue?macro=true";
import { default as browse_45topicyPJ1syiNwMMeta } from "/code/pages/browse/browse-topic.vue?macro=true";
import { default as indexCLit7sRKlPMeta } from "/code/pages/browse/index.vue?macro=true";
import { default as _91slug_93qaNm4HpskPMeta } from "/code/pages/browse/shows/[slug].vue?macro=true";
import { default as _91slug_93qogmdE5KqZMeta } from "/code/pages/browse/shows/episode/[slug].vue?macro=true";
import { default as careers8iEHHXIZsXMeta } from "/code/pages/careers.vue?macro=true";
import { default as community9rTOAH9dSVMeta } from "/code/pages/community.vue?macro=true";
import { default as confirmbA8LHwMBMBMeta } from "/code/pages/confirm.vue?macro=true";
import { default as contactSU7jDK0YajMeta } from "/code/pages/contact.vue?macro=true";
import { default as correctionsx5nlD7khNMMeta } from "/code/pages/corrections.vue?macro=true";
import { default as dashboardkg3zHanmvoMeta } from "/code/pages/dashboard.vue?macro=true";
import { default as diversity_45dei_45overviewsdHAFm778UMeta } from "/code/pages/diversity-dei-overview.vue?macro=true";
import { default as eventseQNvrAL6PPMeta } from "/code/pages/events.vue?macro=true";
import { default as homeX8uzRDotlsMeta } from "/code/pages/home.vue?macro=true";
import { default as indexKRDw4UYEiPMeta } from "/code/pages/index.vue?macro=true";
import { default as live5mbVcPZwkPMeta } from "/code/pages/live.vue?macro=true";
import { default as loginT5FXYAtKoAMeta } from "/code/pages/login.vue?macro=true";
import { default as mobileYA7sX8ZfmhMeta } from "/code/pages/mobile.vue?macro=true";
import { default as newsletterGdU2fykv4WMeta } from "/code/pages/newsletter.vue?macro=true";
import { default as _91slug_93KXvC9j0528Meta } from "/code/pages/npr/[slug].vue?macro=true";
import { default as _91slug_93TrDGnZxpOuMeta } from "/code/pages/people/[slug].vue?macro=true";
import { default as pressLZIlz7pkBlMeta } from "/code/pages/press.vue?macro=true";
import { default as privacyGRokESy9tcMeta } from "/code/pages/privacy.vue?macro=true";
import { default as saved4f9zwGHMNIMeta } from "/code/pages/saved.vue?macro=true";
import { default as signup8HRXv4VtmlMeta } from "/code/pages/signup.vue?macro=true";
import { default as _91slug_931e6Q4Y4DS0Meta } from "/code/pages/staff/[slug].vue?macro=true";
import { default as _91slug_93WE0AkHGwJjMeta } from "/code/pages/story/[slug].vue?macro=true";
import { default as _91gallerySlug_9301CAhoaNksMeta } from "/code/pages/story/photos/[gallerySlug].vue?macro=true";
import { default as support00HN4fIoRzMeta } from "/code/pages/support.vue?macro=true";
import { default as termsEzIw6jSVmbMeta } from "/code/pages/terms.vue?macro=true";
import { default as test_45routeeIfn1udBV6Meta } from "/code/pages/test-route.vue?macro=true";
export default [
  {
    name: "100",
    path: "/100",
    component: () => import("/code/pages/100.vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/code/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "archives-collections",
    path: "/archives/collections",
    component: () => import("/code/pages/archives/collections.vue").then(m => m.default || m)
  },
  {
    name: "browse-browse-topic",
    path: "/browse/browse-topic",
    component: () => import("/code/pages/browse/browse-topic.vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/code/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-shows-slug",
    path: "/browse/shows/:slug()",
    component: () => import("/code/pages/browse/shows/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse-shows-episode-slug",
    path: "/browse/shows/episode/:slug()",
    component: () => import("/code/pages/browse/shows/episode/[slug].vue").then(m => m.default || m)
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/code/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "community",
    path: "/community",
    component: () => import("/code/pages/community.vue").then(m => m.default || m)
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirmbA8LHwMBMBMeta || {},
    component: () => import("/code/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/code/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "corrections",
    path: "/corrections",
    component: () => import("/code/pages/corrections.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/code/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "diversity-dei-overview",
    path: "/diversity-dei-overview",
    component: () => import("/code/pages/diversity-dei-overview.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/code/pages/events.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeX8uzRDotlsMeta || {},
    component: () => import("/code/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexKRDw4UYEiPMeta || {},
    component: () => import("/code/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "live",
    path: "/live",
    component: () => import("/code/pages/live.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/code/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "mobile",
    path: "/mobile",
    component: () => import("/code/pages/mobile.vue").then(m => m.default || m)
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/code/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "npr-slug",
    path: "/npr/:slug()",
    component: () => import("/code/pages/npr/[slug].vue").then(m => m.default || m)
  },
  {
    name: "people-slug",
    path: "/people/:slug()",
    component: () => import("/code/pages/people/[slug].vue").then(m => m.default || m)
  },
  {
    name: "press",
    path: "/press",
    component: () => import("/code/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/code/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "saved",
    path: "/saved",
    component: () => import("/code/pages/saved.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/code/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "staff-slug",
    path: "/staff/:slug()",
    component: () => import("/code/pages/staff/[slug].vue").then(m => m.default || m)
  },
  {
    name: "story-slug",
    path: "/story/:slug()",
    component: () => import("/code/pages/story/[slug].vue").then(m => m.default || m)
  },
  {
    name: "story-photos-gallerySlug",
    path: "/story/photos/:gallerySlug()",
    component: () => import("/code/pages/story/photos/[gallerySlug].vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/code/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/code/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "test-route",
    path: "/test-route",
    component: () => import("/code/pages/test-route.vue").then(m => m.default || m)
  }
]