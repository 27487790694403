<template>
  <svg
    role="img"
    aria-label="live lines icon"
    class="live-lines-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    width="28"
    height="28"
    fill="none"
  >
    <title>Live Lines</title>

    <path
      d="M13.9805 12.48C14.8105 12.48 15.4805 13.15 15.4805 13.98C15.4805 14.81 14.8105 15.48 13.9805 15.48C13.1505 15.48 12.4805 14.81 12.4805 13.98C12.4805 13.15 13.1505 12.48 13.9805 12.48ZM13.9805 10.98C12.3205 10.98 10.9805 12.32 10.9805 13.98C10.9805 15.64 12.3205 16.98 13.9805 16.98C15.6405 16.98 16.9805 15.64 16.9805 13.98C16.9805 12.32 15.6405 10.98 13.9805 10.98Z"
      fill="currentColor"
    />
    <path
      d="M5.22047 23.64C5.03047 23.64 4.84047 23.57 4.69047 23.42C2.14047 20.91 0.730469 17.55 0.730469 13.98C0.730469 10.41 2.14047 7.04 4.70047 4.53C5.00047 4.24 5.47047 4.24 5.76047 4.53C6.05047 4.83 6.05047 5.3 5.76047 5.59C3.49047 7.82 2.24047 10.8 2.24047 13.97C2.24047 17.14 3.49047 20.11 5.75047 22.34C6.04047 22.63 6.05047 23.11 5.75047 23.4C5.60047 23.55 5.41047 23.62 5.22047 23.62V23.64Z"
      fill="currentColor"
    />
    <path
      d="M9.26969 20.29C9.07969 20.29 8.88969 20.22 8.74969 20.08C7.08969 18.46 6.17969 16.29 6.17969 13.98C6.17969 11.67 7.09969 9.50004 8.75969 7.87004C9.05969 7.58004 9.52969 7.59004 9.81969 7.88004C10.1097 8.18004 10.0997 8.65003 9.80969 8.94003C8.43969 10.28 7.68969 12.06 7.68969 13.97C7.68969 15.88 8.43969 17.66 9.80969 19C10.1097 19.29 10.1097 19.76 9.81969 20.06C9.66969 20.21 9.47969 20.29 9.27969 20.29H9.26969Z"
      fill="currentColor"
    />
    <path
      d="M22.6501 23.6399C22.4601 23.6399 22.2601 23.57 22.1101 23.42C21.8201 23.12 21.8201 22.65 22.1101 22.36C24.3801 20.13 25.6301 17.15 25.6301 13.98C25.6301 10.81 24.3801 7.83995 22.1201 5.60995C21.8301 5.31995 21.8201 4.83995 22.1201 4.54995C22.4101 4.24995 22.8801 4.24995 23.1801 4.54995C25.7301 7.05995 27.1401 10.42 27.1401 13.99C27.1401 17.56 25.7301 20.93 23.1701 23.44C23.0201 23.58 22.8301 23.65 22.6401 23.65L22.6501 23.6399Z"
      fill="currentColor"
    />
    <path
      d="M18.6 20.29C18.4 20.29 18.21 20.21 18.06 20.06C17.77 19.76 17.78 19.29 18.07 19C19.44 17.66 20.19 15.88 20.19 13.97C20.19 12.06 19.44 10.28 18.07 8.93995C17.77 8.64995 17.77 8.17995 18.06 7.87995C18.35 7.57995 18.83 7.57995 19.12 7.86995C20.78 9.48995 21.69 11.66 21.69 13.97C21.69 16.28 20.77 18.45 19.11 20.08C18.96 20.22 18.78 20.29 18.59 20.29H18.6Z"
      fill="currentColor"
    />
  </svg>
</template>
